import React, { FC } from 'react';
import { graphql } from 'gatsby';
import LdsComponent from '../../components/LdsBody/LdsBody';
import Layout from '../../layout/Layout';
import { LdsPageComponentProps } from './models';
import './LdsPage.scss';

const LdsPage: FC<LdsPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    page: {
      nodes: [
        {
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaDescription,
          seoExternalHreflangs,
          defaultCompositions,
          pageName,
          url,
          businessId,
          platform,
          versionId,
        },
      ],
    },
  },
}) => {
  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      seo={{
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaDescription,
        seoExternalHreflangs,
      }}
      className="lds-page"
    >
      <LdsComponent {...{ pageName, url, businessId, platform, versionId }} />
    </Layout>
  );
};

export const query = graphql`
  query($url: String = "", $lang: String) {
    page: allUmbracoLds(filter: { url: { eq: $url }, lang: { eq: $lang } }) {
      nodes {
        url
        pageName
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        defaultCompositions {
          ...DefaultCompositionsFragment
        }
        body
        versionId
        businessId
        platform
        banner {
          properties {
            title
            variant
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            imageAlt
            backgroundColour
          }
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default LdsPage;
